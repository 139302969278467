<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading" :disabled="loading">
          <v-row>
            <v-col cols="12">
              <v-img
                  height="70"
                  contain
                  src="@/assets/images/logo-woocommerce.svg"
                  style="margin-top: 30px; margin-bottom: 30px;"
              ></v-img>
            </v-col>
          </v-row>

          <!--<v-toolbar color="#674399" flat>
            <v-toolbar-title class="white--text">Woocommerce Schnittstelle</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>-->
          
          <v-form v-model="valid" class="w-100" ref="woocoomerceForm">
            <v-tabs v-model="tab" background-color="#674399" dark>
              <v-tab key="setup">{{ $t('settings.lang_setup') }}</v-tab>
              <v-tab key="settings">{{ $t('settings.lang_settings') }}</v-tab>

              <v-tabs-items touchless v-model="tab">
                <v-tab-item key="setup">
                  <!--Setup-->
                  <v-card-text>
                    <v-container fluid class="pa-2">
                      <v-row>
                        <v-col cols="12">
                          <v-switch
                            v-model="active"
                            inset :loading="loading" :disabled="loading"
                            :label="$t('settings.lang_settings_activ')" hide-details
                          >
                          </v-switch>
                        </v-col>
                        <v-col>
                          <v-text-field :disabled="loading"
                            :rules="[rules.required,rules.https]"
                            outlined
                            dense
                            label="WooCommerce URL"
                            v-model="apiurl"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item key="settings">
                  <!--Settings-->
                  <v-card-text>
                    <v-container fluid class="pt-7 pa-2">
                      <v-row>
                        <v-col cols="12">
                          <v-select
                            v-model="wooCommerce_storageId"
                            :items="storages"
                            :label="$t('settings.lang_storages')"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-form>
            
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading||!valid" :loading="loading" @click="saveSettings">
              {{ $t('generic.lang_save') }}
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import validation from "../../../mixins/validation/validation";
import {mapState} from "vuex";

export default {
  name: "WoocommerceSettings",
  data() {
    return {
      loading: false,
      apiurl: "",
      active: false,
      wooCommerce_setedUp: null,
      wooCommerce_storageId: null,
      storages: [],
      valid: true,
      tab: 0,
    }
  },
  mounted() {
    this.getSettings();
    this.getStorages();
  },
  mixins:[validation],
  computed: {
    ...mapState([
      'api'
    ])
  },
  methods: {
    getStorages() {
      this.loading = true;

      this.axios.post(ENDPOINTS.DATATABLES.ERP.STORAGEOVERVIEW).then((res) => {
        if (res.status == 200) {
          res.data.aaData.forEach((data) => {
            this.storages.push({
              id: parseInt(data[0]),
              name: data[1],
            });
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.WOOCOMMERCE.SETTINGS.GET).then((res) => {
        if (res.data.success) {
          this.apiurl = res.data.woocommerce_url;
          this.active = res.data.woocommerce_active;
          this.wooCommerce_setedUp = res.data.wooCommerce_setedUp;
          this.wooCommerce_storageId = res.data.wooCommerce_storageId;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;


      this.axios.post(ENDPOINTS.WOOCOMMERCE.SETTINGS.UPDATE, {
        woocommerce_url: this.apiurl,
        woocommerce_active: this.active,
        wooCommerce_storageId: this.wooCommerce_storageId,
      }).then((res) => {
        if(res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
        //NAVIGATE TO WOO COMMERCE
        if (this.wooCommerce_setedUp == false) {

          let addendum = "";
          if(process.env.VUE_APP_MODE === "testing"){
            addendum = "app_name=3POS Kasse&scope=read_write&user_id=100&return_url=https://devkasse.3pos.de/settings/moduleSettings/woocommerce&callback_url=https://devapi.3pos.de/pos/update/settings/woocommerceCredentials/"+"%3Fbs%3D" + this.api.auth.posID + "%26bsid%3D" + this.api.auth.accessString + "%26apiToken%3D" + this.api.auth.apiWebToken;
          }else{
            addendum = "app_name=3POS Kasse&scope=read_write&user_id=100&return_url=https://kasse.3pos.de/settings/moduleSettings/woocommerce&callback_url=https://api.3pos.de/pos/update/settings/woocommerceCredentials/"+"%3Fbs%3D" + this.api.auth.posID + "%26bsid%3D" + this.api.auth.accessString + "%26apiToken%3D" + this.api.auth.apiWebToken;
          }

          window.location.href = this.apiurl+"/wc-auth/v1/authorize?"+addendum;
        }
      });


    },
  }

}
</script>