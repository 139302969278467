<template>
  <div>
    <page-title heading="Woocommerce" subheading="Woocommerce" :icon=icon></page-title>
    <div class="app-main__inner">
      <WoocommerceSettings/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import WoocommerceSettings from "../../../components/settings/moduleSettings/WoocommerceSettings";

export default {
  components: {
    WoocommerceSettings,
    PageTitle
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
  })
}
</script>